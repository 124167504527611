import React, {useEffect, useState} from 'react';
import {Form} from "antd";
import {postFetch} from "../../utill/postFetch";
import {MainTable} from "../MainTable/MainTable";
import CategoryForm from "../category/CategoryForm";
import SubSpecialForm from "./SubSpecialForm";

function AppSpecial({id, openNotificationWithIcon}) {

	const [subSpecial, setSubSpesial] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(null)
	const [subID, setId] = useState()
	const [form] = Form.useForm()
	// получаем ид из спецыалистов
	const fetchSpecial = () => {
		setIsLoading(true)
		const method = "GET";
		const path = `sub-special/${id} `;
		postFetch({path, method, value: ""})
		.then((res) => {
			if (res.status === 200) {
				setSubSpesial(res.data.special)
				setIsLoading(false)
			}
		})
		.catch((err) => {
			setIsLoading(false)
			setIsError(err.message)
			openNotificationWithIcon("error", err.code, err.message);
		});
	};

	const columns = [
		{
			title: "№",
			dataIndex: "id",
			key: "id",
			width: 50,
			render: (_, record, index) => {
				return index + 1
			},
		},
		{
			title: "Kategoriya nomlari uzb.",
			dataIndex: "name",
			ket: "name",
		},
		{
			title: "Kategoriya nomlari russ.",
			dataIndex: "nameru",
			ket: "nameru",
		}
	]
	const onEdit = (res) => {
		form.setFieldsValue({
			name: res.name,
			nameru: res.nameru
		})
		setId(res.id)
	}
	const setEd = () => {
		form.name = ""
		form.nameru = ""
	}

	function onDelete(_, key) {
		setIsLoading(true)
		const path = "delete-subspecial";
		const value = {id: key.id};
		postFetch({path, value}).then((res) => {
			if (res.status === 200) {
				fetchSpecial()
				setIsLoading(false)
				openNotificationWithIcon(
					"success",
					"O'chirildi"
				);
			}
		}).catch((err) => {
			openNotificationWithIcon("error", err.code, err.message);
			setIsLoading(false)
		})
	}

	function onEditSubSpecial(val) {
		setIsLoading(true)
		val.id = Number(subID);
		val.special_id = Number(id);
		val.description = "";
		postFetch({path: "update-subspecial", value: val}).then((res) => {
			if (res.status === 200) {
				fetchSpecial()
				setIsLoading(false)
				openNotificationWithIcon(
					"success",
					"O'zgartirildi"
				);
				// const editData = special?.special.find((i)=> i.id === cateID)
			}
		}).catch((err) => {
			openNotificationWithIcon("error", err.code, err.message);
			setIsLoading(false)
		})
	}

	function addSubSpecial(val) {
		setIsLoading(true)
		val.description = ""
		val.special_id = id
		const path = "insert-subspecial"
		postFetch({path, value: val}).then((res) => {
			if (res.status === 200) {
				fetchSpecial()
				setIsLoading(false)
				openNotificationWithIcon(
					"success",
					"Qo'shildi"
				);
			} else {
				openNotificationWithIcon("error", res.code, res.message);
			}
		}).catch((err) => {
			openNotificationWithIcon("error", err.code, err.message);
			setIsLoading(false)
		})
	}

	useEffect(() => {
		fetchSpecial()
	}, []);

	if (isLoading) return 'Loading...'
	if (isError) return 'Ma`lumot yuklanmadi: ' + isError
	return (
		<>
			<MainTable
				pdata={subSpecial}
				pcolumns={columns}
				// pageTitle="Qoshimcha mutaxasisliklar"
				drawerOneTitle="Kategoriya qo'shish"
				drawerThwoTitle="Ma'lumotni taxrirlash"
				add={<SubSpecialForm onFinish={addSubSpecial} buttonTitle="Qo`shish"/>}
				edit={<SubSpecialForm buttonTitle="O`zgartirish"
				                    onFinish={onEditSubSpecial}
				                    form={form}
				                    cateID={subID}/>}
				onEdit={onEdit}
				setEd={setEd}
				onDelete={onDelete}
				pagination={false}
			/>
		</>
	)
}

export default AppSpecial;