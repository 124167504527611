import React, {useEffect, useState} from 'react';
import {postFetch} from "../utill/postFetch";
import {Button, Card, Image, Typography, Tag, notification, Select} from "antd";
import css from "../styles/Complaints.module.css"


const ImageUrl = process.env.REACT_APP_IMAGE_URL
const {Title, Text} = Typography

function AppComplaints(props) {
	// сообщение об успешном изминения данных
	const [api, contextHolder] = notification.useNotification();
	const openNotificationWithIcon = (type, code, message) => {
		api[type]({
			message: code,
			description: message,
			duration: 3,
		});
	};
	const [complaints, setComplaints] = useState([])
	const fetchComplaints = () => {
		postFetch({path: "all-complaint"}).then((res) => {
			if (res.status === 200) {
				setComplaints(res.data)
			} else {
				openNotificationWithIcon("error", res.code, res.message);
			}
		}).catch((err) => {
			openNotificationWithIcon("error", err.code, err.message);
		})
	}
	const deleteComplaints = (id) => {
		const data = {comment_id: id}
		postFetch({path: "delete-complaint", value: data}).then((res) => {
			if (res.status === 200) {
				fetchComplaints()
			} else {
				openNotificationWithIcon("error", res.code, res.message);
			}
			console.log(res)
		}).catch((err) => {
			openNotificationWithIcon("error", err.code, err.message);
		})
	}
	const statusComplaints = (id) => {
		const data = {comment_id: id, status: 2}
		postFetch({path: "status-complaint", value: data}).then((res) => {
			if (res.status === 200) {
				fetchComplaints()
			} else {
				openNotificationWithIcon("error", res.code, res.message);
			}
			console.log(res)
		}).catch((err) => {
			openNotificationWithIcon("error", err.code, err.message);
		})
	}


	useEffect(() => {
		setChangeStatus(complaints)
	}, [complaints]);

	const [changeStatus, setChangeStatus] = useState([])
	const onChangeStatus = (e) => {
		if (e === 0) {
			const filterDataAll = complaints.filter((i) => i.status !== e)
			setChangeStatus(filterDataAll)
		} else if (e === 1) {
			const filterData = complaints.filter((i) => i.status !== e)
			setChangeStatus(filterData)
		} else {
			const Data = complaints.filter((i) => i.status !== e)
			setChangeStatus(Data)
		}
	};


	const dataComplaints = changeStatus.map((i) => {
		const color = i.status === 1 ? "#cd201f" : "green";
		const text = i.status === 1 ? "Yangi xabar" : "Eski xabar";
		return (
			<div className={css.complaintsContainer} key={i.id}>
				<div className={css.complaintsUser1}>
					{i.writer.map((write) =>
						<Card key={write.id} className={css.complaintsUser1Card}>
							<Image src={ImageUrl + write.image} width={80}/>
							<h4>{write.firstname + " " + write.lastname}</h4>
							<Tag color={"#008080"}>+{write.phone}</Tag>
						</Card>
					)}
					<Card>
						<p>{i.date}</p>
						<Text>{i.comment}</Text>
					</Card>
				</div>

				<div className={css.complaintsUser2}>
					{i.userinfo.map((user) =>
						<Card key={user.id} className={css.complaintsUser1Card}>
							<Image src={ImageUrl + user.image} width={80}/>
							<h4>{user.firstname + " " + user.lastname}</h4>
							<Tag color={"#008080"}>+{user.phone}</Tag>
						</Card>
					)}
					<Card>
						<div className={css.complaintsUserBlock3}>
							<Tag color={color}>{text}</Tag>
							<Button style={{marginBottom: 10}} type="primary"
							        onClick={() => statusComplaints(i.id)}>Ko`rilgan</Button>
							<Button type={"primary"} danger onClick={() => deleteComplaints(i.id)}>O`chirish</Button>
						</div>
					</Card>
				</div>

			</div>
		)
	})
	useEffect(() => {
		fetchComplaints()
		//eslint-disable-next-line
	}, []);

	if (complaints.length === 0) {
		return "Yuklanmoqda, yoki ma`lumot mavjut emas"
	}
	return (
		<div>
			{contextHolder}
			<div className={css.complaintsContainer}>
				<div className={css.complaintsUser1Card}>
					<Title level={5}>Saralash</Title>
					<Select onSelect={onChangeStatus} style={{width: "100%"}}>
						<Select.Option value={0}>Xammasi</Select.Option>
						<Select.Option value={1}>Eski Xabar</Select.Option>
						<Select.Option value={2}>Yangi Xabar</Select.Option>
					</Select>
				</div>

			</div>
			{dataComplaints}
		</div>
	)
}

export default AppComplaints;