import React from 'react';
import {Button, Form, Input, Upload} from "antd";

const {Item} = Form

function CategoryForm({onFinish, form, buttonTitle,uploadButton, dataUpload}) {
	return (
		<Form layout="vertical" onFinish={onFinish} form={form}>
			<Item name="name" label="Kategoriya nomi O`zb." rules={[
				{
					required: true,
					message: "Avval kategoriya nomini kiriting",
				},
			]}>
				<Input/>
			</Item>
			<Item name="nameru" label="Kategoriya nomi Russ." rules={[
				{
					required: true,
					message: "Avval kategoriya nomini kiriting",
				},
			]}>
				<Input/>
			</Item>
			<Form.Item name="image" label="Rasm" valuePropName="picture">
				<Upload
					{...dataUpload}
					style={{ width: "100vw" }}
					listType="picture-card"
					progress
					maxCount={1}
				>
					{uploadButton}
				</Upload>
			</Form.Item>
			<Item wrapperCol={{
				offset: 20,
				span: 24,
			}}>
				<Button htmlType="submit" type="primary" size="middle"  >{buttonTitle}</Button>
			</Item>
		</Form>
	);
}

export default CategoryForm;