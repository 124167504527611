import React, {useRef} from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {Button} from "antd";

const defaultFonts = [
	"Arial",
	"Comic Sans MS",
	"Courier New",
	"Impact",
	"Georgia",
	"Tahoma",
	"Trebuchet MS",
	"Verdana"
];

function Agreement({text, desc, Title, postAgreements, titleText, titleButton}) {
	const editorRu = useRef();
	const getSunEditorInstanceRu = (sunEditor) => {
		editorRu.current = sunEditor;
	};


	const sortedFontOptions = [
		"Logical",
		"Salesforce Sans",
		"Garamond",
		"Sans-Serif",
		"Serif",
		"Times New Roman",
		"Helvetica",
		...defaultFonts
	].sort();
	return (<>
		<Title level={4}>{titleText}</Title>
		<SunEditor showToolbar={true} setOptions={{
			buttonList: [["undo", "redo"], ["font", "fontSize"], // ['paragraphStyle', 'blockquote'],
				["bold", "underline", "italic", "strike", "subscript", "superscript"], ["fontColor", "hiliteColor"], ["align", "list", "lineHeight"], ["outdent", "indent"],

				["table", "horizontalRule", "link", "image", "video"], // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
				// ['imageGallery'], // You must add the "imageGalleryUrl".
				// ["fullScreen", "showBlocks", "codeView"],
				["preview", "print"], ["removeFormat"]

				// ['save', 'template'],
				// '/', Line break
			], // Or Array of button list, eg. [['font', 'align'], ['image']]
			defaultTag: "div", minHeight: "300px", showPathLabel: false, font: sortedFontOptions
		}}
		           setContents={desc} onChange={text} getSunEditorInstance={getSunEditorInstanceRu}/>
		<Button onClick={postAgreements} style={{marginTop: 10}} type="primary">{titleButton}</Button>
	</>);
}

export default Agreement;