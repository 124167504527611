import React, {useState} from 'react';
import {Layout, Button, theme} from 'antd';
import {Navigate, Outlet,} from "react-router-dom";
import {
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	ExportOutlined,
} from '@ant-design/icons';
import {useAuthorization} from "../utill/avtorization/useAuthorization";
import {useAuth} from "../utill/avtorization/userAuthorization";
import {eraseCookie} from "../utill/setCookie";
import SidebarMenu from "../components/menu/SidebarMenu";

const {Header, Sider, Content} = Layout;

function AppLayout(props) {
	const exitUser = useAuth(state => state.exitUser)
	const {isAuth} = useAuthorization()
	const signOut = () => {
		exitUser()
		eraseCookie("access_token_admin");
		localStorage.clear();
	}
	const [collapsed, setCollapsed] = useState(false);
	const {
		token: {colorBgContainer},
	} = theme.useToken();



	return isAuth ? (
		<Layout style={{minHeight: '100vh'}}>
			<Sider trigger={null} collapsible collapsed={collapsed}>
				<div className="logo" style={{display: 'flex', padding: '10px', alignItems: 'center'}}>
					<span style={{fontSize: '18px', fontWeight: '600', color: 'lightblue'}}>PROFI</span>
				</div>
				<SidebarMenu/>

			</Sider>
			<Layout className="site-layout" style={{backgroundColor: 'rgb(240, 242, 245)'}}>
				<Header
					style={{
						padding: '5px 25px',
						background: colorBgContainer,
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
						className: 'trigger',
						onClick: () => setCollapsed(!collapsed),
					})}
					<div className="right">
						<Button
							style={{marginLeft: '15px'}}
							type="primary"
							icon={<ExportOutlined/>}
							onClick={signOut}
							danger
						/>
					</div>
				</Header>
				<Content
					style={{
						// margin: '24px 16px',
						padding: 24,
						minHeight: 280,
						background: 'F0F2F5FF',
					}}
				>
					<Outlet/>
				</Content>
				<div style={{textAlign: "center", fontWeight: '700'}}>
					<a href={"https://cyberarea.uz"}>CYBER AREA UZB | STEBIZ</a>
				</div>
			</Layout>
		</Layout>
	) : <Navigate to={"/login"}/>
}

export default AppLayout;