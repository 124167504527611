import axios from "axios";
import { getCookie } from "./setCookie";
const url = process.env.REACT_APP_DB_URL;
export async function postFetch({ path, method = "POST", value = "" }) {
	const config = {
		method: method,
		url: `${url + path}`,
		headers: {
			"Content-Type": "application/json",
			// eslint-disable-next-line no-useless-concat
			Authorization: "Bearer" + " " + getCookie("access_token_admin"),
		},
		data: JSON.stringify(value),
	};
	// console.log("postFetch:", config);
	return axios(config)
	.then((res) => {
		return res;
	})
	.catch((error) => {
		return error;
	});
}
