import React from 'react';
import {AppstoreAddOutlined, InboxOutlined, UserOutlined} from "@ant-design/icons";
import {NavLink, useHref} from "react-router-dom";
import {Menu} from "antd";

function SidebarMenu(){
	const params = useHref()
	const onSelectedMenu = (e) => {
		// console.log(e)
	}
	return (
		<Menu
			onClick={onSelectedMenu}
			theme="dark"
			mode="inline"
			defaultSelectedKeys={[params]}

			items={[
				{
					key: '1',
					icon: <UserOutlined/>,
					label: <NavLink to="/">Faydalanuvchilar</NavLink>,
				},
				{
					key: '2',
					icon: <AppstoreAddOutlined/>,
					label: <NavLink to="/category">Kategoriyalar</NavLink>,
				},
				{
					key: '3',
					icon: <InboxOutlined/>,
					label: <NavLink to="/complaints">Shikoyatlar</NavLink>,
				}, {
					key: '4',
					icon: <InboxOutlined/>,
					label: <NavLink to="/argument">Shartnoma</NavLink>,
				},{
					key: '5',
					icon: <UserOutlined/>,
					label: <NavLink to="/topUsers">Top</NavLink>,
				},
			]}
		/>
	);
};

export default SidebarMenu;
