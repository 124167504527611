import {Button, Form, Input, notification} from "antd";
import {useNavigate} from "react-router-dom";
import {PatternFormat} from "react-number-format";
import {numberStr} from "../utill/numberString";
import {postFetch} from "../utill/postFetch";
import {setCookie} from "../utill/setCookie";
import {useState} from "react";
import {useAuth} from "../utill/avtorization/userAuthorization";

const {Item} = Form
function AppAuthorization() {
	const authUser = useAuth(state => state.authUser)
	const [form] = Form.useForm();
	const [api, contextHolder] = notification.useNotification();
	// иницыализацыя session storage
	// loder
	const [loder, setLoader] = useState(false);
	// натификацыя
	const openNotificationWithIcon = (type, code, message) => {
		api[type]({
			message: code,
			description: message,
			duration: 1500,
		});
	};

	const navigate = useNavigate();
	const onFinish = (value) => {
		value.phone = numberStr(value["phone"]);
		const path = "login";
		postFetch({path, value})
		.then((res) => {
			setLoader(false);
			console.log(res);
			if (res.status === 200) {
				setCookie("access_token_admin", res.data.access_token, res.data.expires_in);
				authUser(res.data.user)
				navigate("/")
				openNotificationWithIcon(
					"success",
					"Xush kelibsiz",
					"Tizimga muvaffaqiyatli kirdingiz"
				);
			} else if (res.code === "ERR_BAD_REQUEST") {
				openNotificationWithIcon(
					"error",
					`${res.code + " " + res.message}`,
					"Xatolik faydalanuvchi mavjut emas"
				);
			} else {
				openNotificationWithIcon("error", res.code, res.message);
			}
		})
		.catch((err) => {
			console.error("message:", err);
			setLoader(false);
			openNotificationWithIcon("error", err.code, err.message);
		});
	};


	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};
	return (
		<div style={styles.LoginLayout}>
			{contextHolder}
			<div className={styles.formBlock}>
				<Form
					name="basic"
					labelCol={{
						span: 24,
					}}
					wrapperCol={{
						span: 24,
					}}
					style={{
						maxWidth: 600,
					}}
					initialValues={{
						remember: true,
					}}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					autoComplete="on"
					form={form}
				>
					<Item
						name="phone"
						rules={[
							{
								required: true,
								message: "Telefon raqamizni kiriting",
							},
						]}
					>
						<PatternFormat
							format="+998 (##) ### ## ##"
							allowEmptyFormatting
							mask="_"
							customInput={Input}
							style={styles.formInp}
						/>
					</Item>
					<Item
						name="password"
						rules={[
							{
								required: true,
								message: "Parolni kiriting",
							},
						]}
					>
						<Input.Password
							placeholder="parol"
							style={styles.formInp}
						/>
					</Item>
					<Item
						// wrapperCol={{
						// 	offset: 10,
						// 	span: 16,
						// }}
					>
						<Button size="middle" block type="primary" htmlType="submit">
							Kirish
						</Button>
					</Item>
				</Form>
			</div>
		</div>
	)
}
const styles = {
	LoginLayout: {
		backgroundColor: "#dcdcdc",
		width: "100vw",
		height: "100vh",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	formBlock: {
		backgroundColor: "antiquewhite",
		padding: "50px",
		/*border-radius: 20px;*/
	},
	formInp: {
		width: "300px",
		fontSize: "20px"
		/*border-radius: 20px;*/
	}
}
export default AppAuthorization