import React from 'react';
import UserCarouselTable from "../components/userCarousel/UserCarouselTable";

function AppUserCarousel(){
	return (
		<div>
			<UserCarouselTable/>
		</div>
	);
};

export default AppUserCarousel;
