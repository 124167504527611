import {create} from "zustand";
import {persist} from "zustand/middleware";

export const useAuth = create(
	persist(
		(set) => ({
			user: {},
			authUser: (data) =>
				set((state) => {
					return {
						user: {
							id: data.id,
							name: data.firstname + " " + data.lastname,
							role_id: data.role_id,
							phone: data.phone,
							image: data.image
						},
					};
				}),
			exitUser: () =>
				set((state) => {
					return {
						user: {
							id: null,
							name: null,
							role_id: null,
							phone: null,
							image: null
						},
					};
				}),
		}),
		{name: "UserAuth"}
	)
);
