import React, {useEffect, useState} from 'react';
import 'suneditor/dist/css/suneditor.min.css';
import {Col, notification, Row, Typography} from "antd";
import Agreement from "../components/appUserAgreemants/Agreement";
import {postFetch} from "../utill/postFetch";

const {Title} = Typography

function AppUserAgreements() {

	// сообщение об успешном изминения данных
	const [api, contextHolder] = notification.useNotification();
	const openNotificationWithIcon = (type, code, message) => {
		api[type]({
			message: code, description: message, duration: 3,
		});
	};

	const [uzDesc, setUzDesc] = useState('');
	const [ruDesc, setRuDesc] = useState('');


	const testRu = (content) => {
		setRuDesc(content)
	}

	const testUz = (content) => {
		setUzDesc(content)
	}


	const ruAgreements = () => {
		postFetch({path: "policy/ru", method: "GET"}).then((res) => {
			if (res.status === 200) {
				setRuDesc(res.data.text)
			} else {
				console.log("warning:", res)
			}
		}).catch((err) => {
			console.log(err)
		})
	}

	const uzAgreements = () => {
		postFetch({path: "policy/uz", method: "GET"}).then((res) => {
			if (res.status === 200) {
				setUzDesc(res.data.text)
			} else {
				console.log("warning:", res)
			}
		}).catch((err) => {
			console.log(err)
		})
	}

	useEffect(() => {
		uzAgreements()
		ruAgreements()
	}, []);


	const postAgreementsRu = () => {
		const data = {text: ruDesc}
		postFetch({path: "policy/ru", method: "POST", value: data}).then((res) => {
			if (res.status === 200) {
				openNotificationWithIcon('success', "Сохранено")
			} else {
				openNotificationWithIcon('error', "Ошибка при сохранение")
			}
		}).catch((err) => {
			console.log(err)
		})
	}

	const postAgreementsUz = () => {
		const data = {text: uzDesc}
		postFetch({path: "policy/uz", method: "POST", value: data}).then((res) => {
			if (res.status === 200) {
				openNotificationWithIcon('success', "Saqlandi")
			} else {
				openNotificationWithIcon('error', "Saqlanmadi")
			}
		}).catch((err) => {
			console.log(err)
		})
	}


	return (<>
		{contextHolder}
		<Row gutter={12} justify="space-between">
			<Col span={12}>
				<Agreement Title={Title} titleText='На русском языке' titleButton='Сохранить'
				           desc={ruDesc} text={testRu} postAgreements={postAgreementsRu}/>
			</Col>
			<Col span={12}>
				<Agreement Title={Title} titleText='O`zbek tilida' titleButton='Saqlash' desc={uzDesc} text={testUz}
				           postAgreements={postAgreementsUz}/>
			</Col>
		</Row>
	</>);
}

export default AppUserAgreements;