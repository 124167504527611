import {useAuth} from "./userAuthorization";
import {getCookie} from "../setCookie";

export function useAuthorization() {
	const {
		id,
		name,
		role_id,
		phone,
		image
	} = useAuth((state) => state.user);
	return {
		isAuth: !!getCookie("access_token_admin"),
		id,
		name,
		role_id,
		phone,
		image
	};
}
