import React, {useEffect, useState} from 'react';
import {Image, notification, Select, Tag} from "antd";
import {postFetch} from "../utill/postFetch";
import {MainTable} from "../components/MainTable/MainTable";
import noImage from "../img/noimage.png"
import swal from "sweetalert";

const {Option} = Select
const imgUrl = process.env.REACT_APP_IMAGE_URL

function AppUsers(props) {
	// сообщение об успешном изминения данных
	const [api, contextHolder] = notification.useNotification();
	const openNotificationWithIcon = (type, code, message) => {
		api[type]({
			message: code,
			description: message,
			duration: 3,
		});
	};
	const [fetchData, setFetchData] = useState([])
	const [loading, setLoading] = useState(false)
	const fetchAllUser = () => {
		setLoading(true)
		postFetch({path: "getall-users"}).then((res) => {
			// console.log("user",res.data)
			if (res.status === 200) {
				setFetchData(res.data)
				setLoading(false)
			}
		}).catch((err) => {
			openNotificationWithIcon("error", err.code, err.message);
			setLoading(false)
		})
	}
	const onDelete = (e, reg) => {
		swal({
			title: "Точно удалить!",
			text: `после удаления вы не сможете восстановить данные`,
			icon: "warning",
			buttons: {
				cancel: "Нет!",
				catch: {
					text: "Да",
					value: "Ok",
				},
			},
		}).then((willDelete) => {
			if (willDelete === "Ok") {
				postFetch({path: `delete-user/${reg.id}`, method: "GET",}).then((res) => {
					if (res.status === 200) {
						openNotificationWithIcon("success", "Пользователь удалёно");

						const newData = fetchData.filter((item) => item.id !== reg.id)
						setFetchData(newData)

					}
				}).catch((err) => {
					openNotificationWithIcon("error", "Ошибка");
				})
			}
		});
	}


	useEffect(() => {
		const path = "getone-user"
		const value = {user_id: 40}
		postFetch({path, value}).then((res) => {
			// console.log(res)
		}).catch((err) => {
			return err
		})
	}, []);

	useEffect(() => {
		fetchAllUser()
	}, []);


	const columns = [
		{
			title: "№",
			dataIndex: "id",
			key: "id",
			width: 50,
			render: (_, record, index) => {
				return index + 1
			},
		},
		{
			title: "Rasmi",
			dataIndex: "image",
			width: 200,
			render: (_, rec) => {
				let color = rec.status === 0 ? "#d9d9d9" : rec.status === 1 ? "#ff4d4f" : "#52c41a"
				let text = rec.status === 0 ? "blocklangan" : rec.status === 1 ? "kutilmoqda" : "tastiqlangan"

				const topColor = rec.top === 0 ? "#d9d9d9" : rec.top === 1 ? "#ff4d4f" : "#52c41a"
				const topText = rec.top === 1 ? "Top" : null


				const img = rec.image !== null ? <Image width={100} src={imgUrl + rec.image} alt={"img"}/> :
					<Image width={100} src={noImage} alt={"img"}/>
				return <div className="user__image-block" >
					{img}
					<Tag color={color}>{text}</Tag>
					<Tag color={topColor}>{topText}</Tag>
				</div>
			}
		},
		{
			title: "Ismi",
			dataIndex: 'firstname',
			render: (_, rec) => {
				const firstname = rec.firstname !== null ? rec.firstname :	<Tag color="#f50">no data</Tag>
				const lastName = rec.lastname !== null ? rec.lastname :<Tag color="#f50">no data</Tag>
				return firstname + " " + lastName
			}
		},
		{
			title: 'Mansabi',
			dataIndex: 'role_id',
			render: (_, rec) => <>{(rec.role_id === 2) ? "Buyurtmachi" : (rec.role_id === 3) ? "Mutaxasis" : "Administrator"}</>
		},

		{
			title: "Viloyat",
			dataIndex: "distirct",
			render: (_, rec) => {
				return rec.distirct !== null ? rec.distirct.vil_name :
					<Tag color="#f50">no data</Tag>
			}
		},
		// {
		// 	title: "Tuman",
		// 	dataIndex: "region_id",
		// 	render: (_, rec) => {
		// 		const value = rec.region_id
		// 		const path = "tuman"
		// 		postFetch({path, value}).then((res) => {
		// 			console.log(res)
		// 		}).catch((err) => {
		// 			return err
		// 		})
		// 	}
		// }
		{
			title: "Telefon",
			dataIndex: "phone",
			render: (_, rec) => {
				return "+" + rec.phone
			}
		},
		{
			title: "Reyting",
			dataIndex: "reyting",
			render: (_, rec) => {
				return rec.reyting

			}
		},
		{
			title: "Xunarlar",
			dataIndex: "special",
			render: (_, rec) => {
				return rec.special !== null ?
					<p>{rec.special.name}</p> : <Tag color="#f50">no data</Tag>

			}
		},
		{
			title: "Qo'shimcha xunarlar",
			dataIndex: "sub_special",
			render: (_, rec) => {
				return rec.sub_special !== null ?
					<p>{rec.sub_special.name}</p> : <Tag color="#f50">no data</Tag>

			}
		},
		{
			title: "Batafsil ma`lumot",
			dataIndex: "description",
			render: (_, rec) => {
				return rec.description !== null ? rec.description : <Tag color="#f50">no data</Tag>
			}
		},
		{
			title: "Status o`zgartirish",
			width: 180,
			render: (_, rec) => {
				const onStatus = (id) => {
					const value = {user_id: rec.id, status: id}
					const path = "update-status"
					postFetch({path, value}).then((res) => {
						fetchAllUser()
						openNotificationWithIcon(
							"success",
							"O'zgartirildi"
						);
					}).catch((err) => {
						openNotificationWithIcon("error", err.code, err.message);
					})
				}

				return (
					<Select style={{width: "100%"}} onSelect={onStatus} defaultValue="default">
						<Option value="default" disabled={true}>Tanlash</Option>
						<Option value={0} style={{color: "red"}}>Bloklash</Option>
						<Option value={2} style={{color: "#52c41a"}}>Tasdiqlash</Option>
					</Select>
				)
			}
		},
		{
			title: "Top ga chiqarish",
			width: 180,
			render: (_, rec) => {
				const onStatus = (num) => {
					const value = {id: rec.id, top: num}
					postFetch({path: "settop", value}).then((res) => {
						const oneUser = fetchData.find(item=> item.id === rec.id)
						oneUser.top = res.data
						setFetchData([...fetchData, oneUser])
						if(res.data === 1){
							openNotificationWithIcon(
								"success",
								"Top ga chiqarildi"
							);
						}else{
							openNotificationWithIcon(
								"warning",
								"Top dan chiqarildi"
							);
						}
					}).catch((err) => {
						openNotificationWithIcon("error", err.code, err.message);
					})
				}

				return (
					<Select style={{width: "100%"}} onSelect={onStatus} defaultValue="default" >
						<Option value="default"  disabled={true} >Tanlash</Option>
						<Option value={0} style={{color: "red"}}>Top dan chiqarish</Option>
						<Option value={1} style={{color: "#52c41a"}}>Top ga chiqarish</Option>
					</Select>
				)
			}
		},

	]

	if (loading) return "Yuklanmoqda..."
	return (
		<>
			{contextHolder}
			<MainTable pageTitle="Foydalanuvchilar" pcolumns={columns} pdata={fetchData} onDelete={onDelete}/>
		</>
	);
}

export default AppUsers;