import "./index.css"
import {Route, Routes} from "react-router-dom";
import AppSpecial from "./page/AppSpecial";
import AppUsers from "./page/AppUsers";
import AppComplaints from "./page/AppComplaints";
import AppAuthorization from "./page/AppAuthorization";
import AppLayout from "./page/AppLayout";
import AppUserAgreements from "./page/AppUserAgreements";
import AppUserCatousel from "./page/AppUserCarousel";

function App() {
	return (
		<Routes>
			<Route path="/" element={<AppLayout/>}>
				<Route index element={<AppUsers/>}></Route>
				<Route path={"/category"} element={<AppSpecial/>}></Route>
				<Route path={"/complaints"} element={<AppComplaints/>}></Route>
				<Route path={"/argument"} element={<AppUserAgreements/>}></Route>
				<Route path={"/topUsers"} element={<AppUserCatousel/>}></Route>
			</Route>
			<Route path="/login" element={<AppAuthorization/>}/>
		</Routes>
	);
}

export default App;
